import {css, html, LitElement} from 'lit';
import {customElement, property, query, state} from 'lit/decorators.js';
import {ChatController} from '../controllers/chat-controller';
import {Message} from '../models/message';

@customElement('chat-container')
export class ChatContainer extends LitElement {
  static override styles = css`
    .overlay {
      min-width: 100%;
      height: 100%;
    }
    
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 90%;
      position: relative;
    }

    .chat-input {
      width: 60%;
    }

    .message-container {
      margin-bottom: 10px;
      height: 83vh;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }

    .chat-message {
      padding-left: 10px;
      padding-right: 10px;
    }

    .chat-input {
      position: fixed;
      bottom: 0;
      max-width: 694px;
      width: 100%;
      margin-bottom: 60px;
      z-index: 1;
    }

    .chat-input sl-input img {
      display: none;
    }

    .chat-input sl-input img.active {
      display: block;
    }
  `;

  @property() userInput: string = '';
  @property() chatTitle: string = '';
  @property() chatId: string = '';

  @state() private chatController = new ChatController(this);
  @query('#messageContainer') private messageContainer?: HTMLElement;

  override render() {
    return html`
      <div class="overlay">
        <northell-header viewTitle="${this.chatTitle}"></northell-header>
        <div class='container'>
          <div id='messageContainer' class='message-container'>
            ${this.renderMessages()}
          </div>
          ${this.renderInput()}
        </div>
        <northell-footer></northell-footer>
      </div>
    `;
  }

  override update(changedProperties: Map<string, unknown>) {
    if (changedProperties.has("chatId")) {
      this.chatController.loadPrompts(this.chatId);
    }
    super.update(changedProperties);
  }

  override updated(): void {
    this.updateComplete.then(() => {
      this.scrollToBottom();
    });
  }

  private scrollToBottom() {
    // Find the ancestor main element or another element with scrolling
    const scrollingElement = this.closest('main') || this.messageContainer;
    scrollingElement?.scrollTo({
      top: scrollingElement.scrollHeight,
      behavior: 'smooth'
    });
  }

  private renderMessages() {
    return this.chatController.messages.map((message) => this.renderMessage(message));
  }

  private renderMessage(message: Message) {
    return html`
      <div class='chat-message'>
        <chat-message
          .messageType='${message.sender}'
          .text='${message.text}'
          .displayFeedback='${message.askForFeedback}'
          .responseId='${message.responseId}'
          .feedbackLabel='${message.feedbackLabel}'
          .urls="${message.urls}"
          @send-feedback='${this.handleSendFeedback}'>
        </chat-message>
      </div>
    `;
  }

  private renderInput() {
    return html`
      <form class='chat-input' @submit='${this.handleFormSubmit}'>
        <sl-input placeholder='How can I help you today?' size='medium' .value='${this.userInput}'
                  @sl-input='${this.onInputChange}'>
          <div class='${!this.userInput ? 'active' : ''} image-placeholder' slot='suffix'></div>
          <img class='${!this.userInput ? 'active' : ''}' slot='suffix' src='/static/images/send-button.svg'
               alt='Send button' @click='${this.onMessageSent}'>
          <img class='${this.userInput ? 'active' : ''}' slot='suffix' src='/static/images/send-button-filled.svg'
               alt='Send button' @click='${this.onMessageSent}'>
        </sl-input>
      </form>
    `;
  }

  private async handleFormSubmit(event: SubmitEvent) {
    event.preventDefault();
    await this.onMessageSent();
  }

  private onInputChange(event: Event) {
    this.userInput = (event.target as HTMLInputElement).value;
  }

  private async onMessageSent(): Promise<void> {
    const trimmedUserInput = this.userInput.trim();
    if (trimmedUserInput) {
      this.userInput = '';
      this.requestUpdate();
      try {
        await this.chatController.sendMessage(trimmedUserInput, this.chatId);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  }

  private async handleSendFeedback(event: CustomEvent): Promise<void> {
    const {responseId, feedbackLabel} = event.detail;
    if (responseId && feedbackLabel) {
      try {
        await this.chatController.sendFeedback(responseId, feedbackLabel);
      } catch (error) {
        console.error('Error sending feedback:', error);
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'chat-container': ChatContainer;
  }
}